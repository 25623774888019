<template>
    <div class="modal-collabs row d-none" :id="'collabModal'+id" v-if="collabs">
        <div class="col-12 d-flex flex-column justify-content-start align-items-center modal-row px-4" :class="collabs.length >= 3 ? 'row-height' : ''">
            <div class="row justify-content-center align-items-start row__width">
                <div class="col-12 d-flex justify-content-end align-items-center pt-4 px-0">
                    <button type="button" class="btn-none" @click="closeModal(id)"><img src="../../img/modales-icon/cerrar.svg" alt=""></button>
                </div>
            </div>
            <div class="row justify-content-center align-items-start ver__perfiles">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center h-100 pb-4">
                    <div class="row justify-content-center align-items-start row__width" id="myTabContent">
                        <div class="col-12 d-flex justify-content-start align-items-center px-0 pb-4">
                            <h2>Colaboradores</h2>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-start align-items-center scroll-y pb-3">
                            <div class="row justify-content-center row__width"  v-if="collabs">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador" v-for="(collab, id) in collabs">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center width">
                                            <img :src="collab.avatar" alt="" class="img">
                                        </div>
                                        <div class="col d-flex flex-column justify-content-center align-items-start">
                                            <h3>{{ collab.name }}</h3>
                                            <h4>{{ collab.profession?.translation?.description}}</h4>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center ps-0 width2">
                                            <VerPerfilColaboradores :slug="collab.slug"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row justify-content-center row__width" v-else>
                                <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center width">
                                            <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img">
                                        </div>
                                        <div class="col d-flex flex-column justify-content-center align-items-start ps-3 ms-1">
                                            <h3>Beatriz Cantu</h3>
                                            <h4>Fotógrafo profesional</h4>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center ps-0 width2">
                                            <VerPerfilColaboradores :slug="'beatriz-cantu'"/>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import VerPerfilColaboradores from "../Botones/VerPerfilColaboradores.vue";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";

const general = useGeneralStore();
const {colaboradores} = storeToRefs(general);
const {} = general;

const props = defineProps({
    rCollabs: '',
    id: '',
})

let collabs = props.rCollabs
let id = props.id

const closeModal = (id) => {
    let element = document.getElementById('collabModal'+id)
    // console.log(element)
    // console.log(element.classList)
    element.classList.add("d-none")
}

</script>

<style lang="scss" scoped>
.colaboradores{
    .modal-header{
        border-bottom: 0;
    }
    .modal-header .btn-close{
        background-image: url('../../img/modales-icon/cerrar.svg');
        opacity: 1;
    }
    .modal-content{
        padding: 20px 30px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
        z-index: 99;
    }
    .modal-dialog{
        max-width: 70%;
    }
    .contador__botones{
        p, h3{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 3px;
        }
        h3{
            font-weight: 400;
            padding-left: 3px;
            padding-top: 2px;
        }
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .col__perso{
            max-width: max-content;
        }
    }
    h5{
        font-weight: 600;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h3{
        font-family: 'Proxima Nova';
        font-weight: 700;
        font-size: 16px;
    }
    h4{
        font-family: 'Proxima Nova';
        font-weight: 400;
        font-size: 15px;
    }
    .tablas{
        h6{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding: 2vh 0 6vh 0;
        }
        .nav-link{
            width: 100%;
            font-weight: 400;
            font-family: 'Proxima Nova';
            font-size: 16px;
            color: #0E1133;
            position: relative;
            padding: 10px 0;
            border: 0;
        }
        .nav-link.active{
            font-weight: 700;
            border: 0;
            color: var(--colorSecondary);
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: 0;
                left: 0;
                border-radius: 50px 50px 0px 0px;
                background-color: var(--colorPrimary);
                
            }
            @media (min-width: 992px) {
                &::before{
                    height: 4px;
                }   
            }
        }
        .scroll{
            overflow-x: scroll;
            max-width: 100%;
            ul{
                min-width: max-content;
                li{
                    min-width: max-content;
                }
            }
            @media (min-width: 992px) {
                overflow: hidden;
                max-width: none;
                width: 100%;
                ul{
                    max-width: 100%;
                    min-width: 1vw;
                    width: 100%;
                }
            }
        }
    }
    .btn-close{
        outline: 0;
        box-shadow: none;
        &:focus{
            box-shadow: none;
            border-color: transparent;
        }
    }
    .scroll-y{
        max-height: 17vh;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            background-color: rgba(219, 219, 219, 0.25);
            border-radius: 200px;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: rgb(219, 219, 219);
            border-radius: 50px;
        }
    }
    .colaborador{
        padding: 10px 0;
        border-bottom: 1px solid #b8c1db42;
        &:last-child{
            border-bottom: 0;
        }
    }
    .width{
        max-width: 60px;
    }
    .img{
        min-height: 55px;
        min-width: 55px;
        max-height: 55px;
        max-width: 55px;
        border-radius: 50%;
    }
    .width2{
        max-width: 150px;
    }
}
.modal-collabs{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0e113399;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-row{
        width: 80%;
        min-height: 25%;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
        z-index: 999;
        h5{
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            font-family: 'Proxima Nova';
            font-weight: 700;
            font-size: 16px;
        }
        h4{
            font-family: 'Proxima Nova';
            font-weight: 400;
            font-size: 15px;
        }
        .btn-close{
            outline: 0;
            box-shadow: none;
            &:focus{
                box-shadow: none;
                border-color: transparent;
            }
        }
        .scroll-y{
            max-height: 17vh;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                background-color: rgba(219, 219, 219, 0.25);
                border-radius: 200px;
                width: 8px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: rgb(219, 219, 219);
                border-radius: 50px;
            }
        }
        .colaborador{
            padding: 10px 0;
            border-bottom: 1px solid #b8c1db42;
            &:last-child{
                border-bottom: 0;
            }
        }
        .width{
            max-width: 60px;
        }
        .img{
            min-height: 40px;
            min-width: 40px;
            max-height: 40px;
            max-width: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
        }
        .width2{
            max-width: 150px;
        }
        @media (min-width: 992px) {
            width: 45%;
        }
    }
    .row-height{
        .scroll-y{
            max-height: 27vh;
        }
    }
}

.btn-none{
    background-color: transparent;
    border: 0;
    outline: 0;
}
</style>