<template>
    <router-link :to="{name: 'Pack', params: {slug: slug}}" class="col-12 col-lg d-flex flex-column justify-content-start align-items-center slide px-0">
    <div class="row justify-content-center row__width caption">
        <div class="col-11 d-flex justify-content-center align-items-center px-0">
            <span class="filtro"><img :src="image" alt="pack-image" class="ejemplo"></span>
            <div class="row justify-content-center row__width position">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div class="row justify-content-center row__width row__slide">
                        <div class="col-12 d-flex justify-content-end align-items-start arriba" v-if="products_quantity > 0">
                            <a href="">{{products_quantity}} {{ $t('message.productsV2') }}</a>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center abajo">
                            <div class="row justify-content-center row__width">
                                <div class="col d-flex justify-content-start align-items-center">
                                    <p class="text-modality">{{ modality }}</p>
                                </div>
                                <div class="col col-lg-5 d-flex justify-content-end align-items-center" v-if="reviews">
                                    <p class="text-reviews">
                                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="rate">{{ reviews.average }}
                                    </p>
                                    <p>{{ reviews.count ? `(${reviews.count})` : $t('message.new') }}</p>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center descripcion row__width px-2">
        <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center">
            <h3>{{name}}</h3>
        </div>
    </div>
    <div class="row justify-content-center listado row__width">
        <div class="scroll__desk">
            <div class="col-12 d-flex justify-content-center align-items-center products" v-for="(product, id) in products_list">
                <div class="row justify-content-center align-items-start row__width border-div py-2">
                    <div class="col-4 col-sm-5 d-flex justify-content-center align-items-center img">
                        <span class="filtro"><img :src="product.presentations[0]?.url" alt="pack-presentation"></span>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center pb-2 pe-0">
                                <h3>{{ product.name }}</h3>
                            </div>
                            <div class="col-12 d-flex justify-content-start align-items-center pe-0">
                                <div class="position-relative d-flex justify-content-center align-items-end">
                                    <img :src="product.professional.avatar" alt="avatar" class="avatar" v-if="product.professional.avatar">
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                                    <p class="text-creator">{{$t('message.creator')}}</p>
                                    <p class="text-professional-name" :title="product.professional.name">{{ product.professional.name }}</p>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between pt-2 flex-cambio">
                                <p class="price" v-if="product.prices[0]?.discount">MXN ${{ product.prices[0].totalPrice }}</p>
                                <p class="text-final-price">MXN ${{ product.prices[0]?.finalPrice }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-start comprar row__width px-2">
        <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0 my-1 my-lg-4" v-if="price">
            <p class="text-price">{{ price.coin_type[0].abbr }} ${{ price.finalPrice }}</p>
            <p class="text-total-price">{{ price.coin_type[0].abbr }} ${{ price.totalPrice }}</p>
        </div>
        <div class="col d-flex justify-content-center align-items-center px-0">
            <router-link :to="{name: 'Pack', params: {slug: slug}}" class="btn__primario btn-width">
                {{$t('message.buy')}}
            </router-link>
        </div>
    </div>
</router-link>

</template>

<script setup>
import BTNPrimario from "../Botones/Primario.vue";
import CursosParaListado from "../Cursos/ParaListado.vue";
import { ref, inject, provide } from 'vue';

//Recibe los parametros de busqueda del componente padre
const props = defineProps({
    rName: '',
    rSlug: '',
    rOwner: '',
    rOwnerAvatar: '',
    rImage: '',
    rModality: '',
    rDetail: '',
    rPrice: '',
    rVerified: '',
    rProductVerified: '',
    rCreatedAt: '',
    rCollabs: '',
    rReviews: '',
    rMarketplace: '',
    rMembership: '',
    rPurchases: '',
    id: '',
    rOwnerSlug: '',
    rProductsQuantity: '',
    productsProducts: '',
})

//Setea las variables con los resultados para su uso
let id = props.id
let name = props.rName
let slug = props.rSlug
let owner = props.rOwner
let ownerSlug = props.rOwnerSlug
let avatar = props.rOwnerAvatar
let image = props.rImage
let modality = props.rModality
let details = props.rDetail
let price = props.rPrice
let verified = props.rVerified
let productVerified = props.rProductVerified
let createdAt = props.rCreatedAt
let collabs = props.rCollabs
let reviews = props.rReviews
let marketplace = props.rMarketplace
let membership = props.rMembership
let purchases_count = props.rPurchases
let products_quantity = props.rProductsQuantity
let products_list = props.productsProducts

provide(products_list, 'products_list')
provide(products_quantity, 'products_quantity')
</script>

<style lang="scss" scoped>
.slide {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    max-height: 550px;

    .caption {
        position: relative;
        margin: 10px 0px;

        .filtro {
            min-width: 100%;
            position: relative;

            img {
                height: 160px;
                width: 100%;
                border-radius: 15px;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 15px;
                z-index: 1;
            }
        }

        .ejemplo {
            max-height: 24vh;
            width: 100%;
        }

        .position {
            position: absolute;
            top: 0;
            min-height: 100%;
            z-index: 2;

            p.text-modality {
                font-weight: 500;
                font-size: 15px;
                color: var(--bgPrincipal);
            }

            p.text-reviews {
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
            }

            p {
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }

            a {
                font-weight: 500;
                font-size: 16px;
                color: var(--bgPrincipal);
                text-decoration: none;
                background-color: var(--colorSecondary);
                border-radius: 28px;
                opacity: 0.73;
                padding: 7px 22px;
            }

            .row__slide {
                position: relative;
                min-height: 100%;

                .arriba {
                    position: absolute;
                    top: 10%;
                }

                .abajo {
                    position: absolute;
                    bottom: 11%;
                }
            }
        }
    }

    .listado {
        height: 200px;
        max-height: 200px;
        overflow-y: scroll;

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 2px;
        }

        @media (min-width: 992px) {
            overflow: auto;
            max-height: none;
            padding: 0 10px;

            .scroll__desk {
                max-height: 250px;
                overflow-y: scroll;

                &::-webkit-scrollbar-thumb {
                    background-color: #DBDBDB;
                    border-radius: 50px;
                }

                &::-webkit-scrollbar {
                    background-color: rgba(219, 219, 219, 0.25);
                    width: 8px;
                    border-radius: 50px;
                }
            }
        }
    }

    .descripcion {
        padding: 10px 0px;

        h3 {
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
            min-height: 53px;
        }
    }

    .comprar {
        margin-bottom: 5px;
        margin-top: 15px;

        .text-price {
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
        }

        .text-total-price {
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
        }

        .padding {
            padding-left: 10px;
        }
    }

    @media (min-width: 992px) {
        max-width: 96%;
    }
}


.products{
    h3{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .text-creator{
        color: #B8C1DB;
        font-weight: 500;
        font-size: 15px;
    }
    .text-professional-name{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p{
        color: var(--bgPrincipal);
        font-weight: 500;
        font-size: 15px;
    }
    .text-final-price{
        font-weight: 700;
        font-size: 20px;
        color: var(--colorSecondary);
        padding-left: 5px;
        @media (max-width: 992px) {
            font-size: 16px;
        }
    }
    .price{
        font-size: 14px;
        font-weight: 400;
        color: #B8C1DB;
        text-decoration: line-through;
    }
    .border-div{
        border-bottom:  1px solid rgba(184, 193, 219, 0.3);
        border-top: 1px solid rgba(184, 193, 219, 0.3);
        @media (max-width: 375px){
            .flex-cambio{
                flex-direction: column;
                align-items: flex-start !important;
                h5{
                    padding-left: 0;
                }
            }
        }
        @media (min-width: 376px){
            .flex-cambio{
                align-items: center !important;
            }
        }
        
    }
    .verificacion{
        position: absolute;
        left: 25px;
    }

    .img{
        position: relative;
        padding: 0;
        .filtro{
            width: 100%;
            img{
                height: 118px;
                width: 100%;
                border-radius: 15px;
                object-fit: cover;
                /* @media (max-width: 992px) {
                    height: 60px;
                } */
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 118px;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 15px;
                z-index: 1;
            }
            /* @media (max-width: 992px) {
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 60px;
                    background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                    filter: opacity(1);
                    border-radius: 15px;
                    z-index: 1;
                }
            } */
        }
        button{
            border: 0;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 52px;
            background-color: #fff;
            outline: 0;
            height: 36px;
            width: 36px;
            position: absolute;
            left: -15px;
            z-index: 2;
            img{
                max-height: 15px;
            }
        }
    }
    .avatar{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
    }
}
</style>